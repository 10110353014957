import Header from './Header';
import styled, { keyframes } from 'styled-components';
import { bounce, fadeIn, fadeInDown, fadeInUp } from 'react-animations';

const FadeInDown = styled.div`animation: 1s ${keyframes`${fadeInDown}`} 1`;
const FadeInUp = styled.div`animation: 1s ${keyframes`${fadeInUp}`} 1`;

function Step(props) {
    return (
        <div className={"step" + (props.className ? " " + props.className : "")}>
            { props.withHeader && <Header/> }
            <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center step-body">
                {props.children}
            </div>
            { props.footer && <FadeInUp><footer>{props.footer}</footer></FadeInUp> }
        </div>
    );
}

export default Step;