import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Loader from "react-js-loader";


const API_URL = process.env.REACT_APP_API_URL || 'https://api-us.ddmnaturamaquillaje.com';
const API_ERROR_MESSAGE = 'No podemos procesar tu solicitud en este momento. Inténtalo de nuevo más tarde.';
const IMG_WIDTH = 600;
const IMG_HEIGHT = Math.round(IMG_WIDTH * (4/3));

function PhotoProcessor(props) {
  //const [initialized, setInitialized] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [finalImage, setFinalImage] = useState(null);
  const [error, setError] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [debugMessage, setDebugMessage] = useState(null);

  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);

  useEffect(() => {
    //if (!initialized) {
    //  setInitialized(true);
      if (props.cameraAvailable && cameraInputRef.current) {
        cameraInputRef.current.click();
      } else if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    //}
  }, []);

  useEffect(() => {
    let timer;
    if (isLoading) {
      setDebugMessage(null);
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setDebugMessage(elapsedTime === 0 ? null : `Tiempo de procesamiento y transferencia de datos: ${elapsedTime}s`);
      setElapsedTime(0);
    }
    return () => clearInterval(timer);
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      // scroll to error message
      const errorElement = document.getElementById('error-message');
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [error]);

  useEffect(() => {
    if (finalImage) {
      // scroll to result
      const resultElement = document.getElementById('result');
      if (resultElement) {
        resultElement.scrollIntoView({ behavior: 'smooth' });
      }

      if (props.handleImageProcessed) {
        props.handleImageProcessed(finalImage);
      }
    }
  }, [finalImage]);


  useEffect(() => {
    if (imageSrc) {
      handleSubmit();
    }
  }, [imageSrc]);
  
  const handleReset = () => {
    setError(null);
    setIsLoading(false);
    setImageSrc(null);
    setFinalImage(null);
    setElapsedTime(0);
    setDebugMessage(null);
    if(props.handleReset) {
      props.handleReset();
    }
  };

  const resizeImage = (src, flip_horizontal) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Target dimensions
        const targetWidth = IMG_WIDTH;
        const targetHeight = IMG_HEIGHT;

        // Calculate aspect ratios
        const imgAspectRatio = img.width / img.height;
        const targetAspectRatio = targetWidth / targetHeight;

        let drawWidth, drawHeight, offsetX, offsetY;

        if (imgAspectRatio > targetAspectRatio) {
          // Image is wider relative to its height
          drawWidth = img.width * (targetHeight / img.height);
          drawHeight = targetHeight;
          offsetX = (drawWidth - targetWidth) / 2;
          offsetY = 0;
        } else {
          // Image is taller relative to its width
          drawWidth = targetWidth;
          drawHeight = img.height * (targetWidth / img.width);
          offsetX = 0;
          offsetY = (drawHeight - targetHeight) / 2;
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;

        if (flip_horizontal) {
          // flip the image horizontally
          ctx.translate(targetWidth, 0);
          ctx.scale(-1, 1);
        }

        // Draw the image centered on the canvas, covering the entire canvas
        ctx.drawImage(
          img,
          -offsetX, -offsetY, drawWidth, drawHeight
        );

        const resizedImageSrc = canvas.toDataURL('image/jpeg');
        resolve(resizedImageSrc);
      };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const isCapture = e.target.name === 'capture_image';
      const reader = new FileReader();
      reader.onload = async (event) => {
        const resizedImage = await resizeImage(event.target.result, isCapture);
        setError(null);
        setImageSrc(resizedImage);
        setDebugMessage(null);
        setFinalImage(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchWithTimeout = (url, options = {}, timeout = 15000) => {
    // Create a promise that rejects after a specified timeout
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), timeout)
    );
  
    // The actual fetch request promise
    const fetchPromise = fetch(url, options);
  
    // Use Promise.race to race between fetch and timeout
    return Promise.race([fetchPromise, timeoutPromise]);
  };

  const handleSubmit = async () => {
    // ping api to check if server is up
    try {
      setError(null);
      setIsLoading(true);
      const response = await fetchWithTimeout(
        `${API_URL}/ping/`,
      );

      if (!response.ok) {
        setIsLoading(false);
        throw new Error(API_ERROR_MESSAGE);
      } else {
        submitImage();
      }
    } catch (error) {
      //console.error('API did not respond to ping', error);
      setError(API_ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  const submitImage = async () => {
    setIsLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('file', dataURLtoFile(imageSrc));

    try {
      setFinalImage(null);
      const response = await fetch(
        `${API_URL}/process/?makeup=${props.makeup}&background=${props.background}&uid=${props.uid}&enhance=true`,
        {
          method: 'POST',
          body: formData,
        }
      );

      if (!response.ok) {
        const json = await response.json();
        const error = json.detail || json.error || response.error || API_ERROR_MESSAGE;
        throw new Error(error);
      }
      
      const result = await response.json();
      if (!result.url) {
        const error = response.error || API_ERROR_MESSAGE;
        throw new Error(error);
      }

      const resultUrl = (result.url && result.url.indexOf('?') > 0 ? result.url : result.url + '?') + 'v=' + new Date().getTime();
      setFinalImage(resultUrl);
      setImageSrc(null);
    } catch (error) {
      console.error('API response error', error);
      setError( (error.message && error.message !== 'Failed to fetch') ? error.message : API_ERROR_MESSAGE);
      setFinalImage(null);
      setImageSrc(null);
    } finally {
      setIsLoading(false);
    }
  };

  const dataURLtoFile = (dataurl) => {
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <>
      {
        error && (
          <>
            <img src="img/decor1.png" alt="Natura" className="w-decor-lg mb-3" />
            <p id='error-message' className='error-message'>{error}</p>
            <Button variant='primary' onClick={handleReset} style={{ marginTop: '20px' }}>
              Vuelve a intentar
            </Button>
          </>
        )
      }

      {
        /*debugMessage && (
          <p style={{ color: 'blue' }} id='debug-message'>{debugMessage}</p>
        )*/
      }

      {
        isLoading && (
          <>
            <div className="image-wrapper">
              <img src={imageSrc} alt="Foto" />
              <div className="loader-wrapper d-flex flex-column align-items-center justify-content-center">
                <Loader type="bubble-ping" bgColor={"#ffffff"} color={"#ffffff"} size={100} />
                <p className='mt-4'>Aguarda por favor...</p>
                <p id='debug-message' style={{display: 'none'}}>Procesando... {elapsedTime}s</p>
              </div>
            </div>
          </>
        )
      }

      {/*
        imageSrc && !isLoading && !error && (
          <div>
            <Button variant='primary' onClick={handleSubmit} disabled={!imageSrc} style={{ marginBottom: '20px' }}>
              Enviar
            </Button>
            <div style={pictureStyle}>
              <img
                src={imageSrc}
                alt="Imagen capturada"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>
        )
      */}

      {
        !imageSrc && !error && (
          <>
            {props.cameraAvailable && (
              <>
                <Button variant='primary' style={{ marginBottom: '20px' }} onClick={() => cameraInputRef.current && cameraInputRef.current.click()}>
                  Tomar una foto
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  capture="user"
                  name="capture_image"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  ref={cameraInputRef}
                />
              </>
            )}
            {!props.cameraAvailable && (
              <>
                <Button variant='primary' style={{ marginBottom: '20px' }} onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                  Elegir una foto de mi galería
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  name="gallery_image"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
              </>
            )}
          </>
        )
      }

    </>
  );
}

export default PhotoProcessor;
