import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import styled, { keyframes } from 'styled-components';
import { fadeIn, bounceInLeft, bounceInRight, tada, pulse, flipInY } from 'react-animations';
import PhotoProcessor from './components/PhotoProcessor';
import Step from './components/Step';

const FadeIn = styled.div`animation: 2s ${keyframes`${fadeIn}`} 1`;
const BounceInLeft = styled.div`animation: 1s ${keyframes`${bounceInLeft}`} 1`;
const BounceInRight = styled.div`animation: 2s ${keyframes`${bounceInRight}`} 1`;
const Tada = styled.div`animation: 1s ${keyframes`${tada}`} 1`;
const Pulse = styled.div`animation: 1s ${keyframes`${pulse}`} 1`;
const FlipInY = styled.div`animation: 2s ${keyframes`${flipInY}`} 1`;



const DATA_URL = process.env.REACT_APP_API_URL || 'https://admin.ddmnaturamaquillaje.com';
const API_ERROR_MESSAGE = 'No podemos procesar tu solicitud en este momento. Inténtalo de nuevo más tarde.';

const URL_FINAL = process.env.REACT_APP_URL_FINAL || 'https://natura.com.mx';
const FINAL_TIMEOUT = process.env.REACT_APP_FINAL_TIMEOUT || 5*1000;

const maquillajes = [1,2,3,4,5,6];

function App() {

  const [step, setStep] = useState(0);
  const videoRef = useRef(null);
  const [tapCount, setTapCount] = useState(0);
  const [tapTimeout, setTapTimeout] = useState(null);
  const [cameraAvailable, setCameraAvailable] = useState(false);
  const [sharerAvailable, setSharerAvailable] = useState(false);
  const [fondo, setFondo] = useState('1');
  const [marco, setMarco] = useState('1');

  const [uid, setUid] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [email, setEmail] = useState(null);
  //const [rutina, setRutina] = useState(null);
  const [lugar, setLugar] = useState(null);
  const [momento, setMomento] = useState(null);
  const [participa, setParticipa] = useState(null);

  const [nombreError, setNombreError] = useState('');
  const [emailError, setEmailError] = useState('');
  //const [rutinaError, setRutinaError] = useState('');

  const [background, setBackground] = useState('1');
  const [makeup, setMakeup] = useState('1');

  const [finalImage, setFinalImage] = useState(null);
  const [error, setError] = useState(null);
  const [finalImageSubmitted, setFinalImageSubmitted] = useState(false);

  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateNotEmpty = (value) => {
    return value !== null && value.trim() !== '';
  };
  
  const handleNombreSubmit = () => {
    if (validateNotEmpty(nombre)) {
      setNombreError('');
      handleNextStep();
    } else {
      setNombreError('Por favor ingresa tu nombre');
    }
  };

  const handleEmailSubmit = () => {
    if (validateEmail(email)) {
      setEmailError('');
      handleNextStep();
    } else {
      setEmailError('Por favor ingresa un email válido');
    }
  };

  /*
  const handleRutinaSubmit = () => {
    if (validateNotEmpty(rutina)) {
      setRutinaError('');
      handleNextStep();
    } else {
      setRutinaError('Por favor ingresa tu rutina de maquillaje');
    }
  };

  const handleTextareaChange = (e) => {
    e.target.style.height = 'auto'; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the height based on scrollHeight
  };
  */

  const handleRedirect = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (!lugar || !momento) {
      setBackground('1');
    } else {
      switch (lugar) {
        case 'Ciudad':
          setBackground(momento === 'Día' ? '1' : '2');
          break;
        case 'Pueblo':
          setBackground(momento === 'Día' ? '3' : '4');
          break;
        case 'Panteón':
          setBackground(momento === 'Día' ? '5' : '6');
          break;
        default:
          setBackground('1');
      }
    }
  }, [lugar, momento]);

  useEffect(() => {
    if (makeup) {
      //submitUserData();
    }
  }, [makeup]);

  useEffect(() => {
    if (step === 8) {
      const uuid = uuidv4() + '-' + new Date().getTime();
      setUid(uuid);
    }

    if (step === 12) {
      submitUserData();
    }

    if (step === 13) {
      setTimeout(() => handleRedirect(URL_FINAL), FINAL_TIMEOUT);
    }

    setFondo([0,1,2,8,9,12].includes(step) ? '1' : step === 13 ? '3' : '2');
    setMarco([0,1,2,3,4,5,6,7].includes(step) ? '1' : '2');
  }, [step]);

  useEffect(() => {
    if (!uid) {
      setUid(uuidv4() + '-' + new Date().getTime());
    }

    //setSharerDetected(navigator.share ? true : false);

    // Check for camera availability
    navigator.mediaDevices.enumerateDevices().then(devices => {
      const hasCamera = devices.some(device => device.kind === 'videoinput');
      setCameraAvailable(hasCamera);
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('test')) {
      setStep(1);
    }
  }, []);



  useEffect(() => {
    if (error) {
      // scroll to error message
      const errorElement = document.getElementById('error-message');
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [error]);

  useEffect(() => {
    if (finalImage) {
      // scroll to result
      const resultElement = document.getElementById('result');
      if (resultElement) {
        resultElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [finalImage]);

  const handleDownload = () => {
    const fileName = finalImage.substring(finalImage.lastIndexOf('/') + 1);
    const link = document.createElement('a');
    link.href = 'https://admin.ddmnaturamaquillaje.com/download/' + fileName;
    link.download = 'ddmnaturamaquillaje.jpg';
    link.click();
  };

  const handleImageProcessed = (image) => {
    if (image) {
      setFinalImage(image);
      handleNextStep();
    }
  };

  const submitUserData = async () => {
    /*console.log('submitUserData', finalImage, finalImageSubmitted, email, uid);*/
    if (!finalImage/* && finalImageSubmitted*/) {
      return;
    }

    if (!email) {
      return;
    }

    if (!uid) {
      return;
    }

    //setIsLoading(true);
    //setError(null);
    const data = {
      'makeup': makeup,
      'background': background,
      'uid': uid,
      'nombre': nombre,
      'email': email,
      'lugar': lugar,
      'momento': momento,
      //'rutina': rutina,
      'participa': participa === 1 ? true : false,
      'imagen': finalImage,
      'fecha_registro': new Date().toISOString(),
    };

    try {
      const response = await fetch(
        `${DATA_URL}/update`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const json = await response.json();
        const error = json.detail || json.error || response.error || API_ERROR_MESSAGE;
        throw new Error(error);
      }

      const result = await response.json();
      console.log(result);

      if (finalImage) {
        setFinalImageSubmitted(true);
      }

    } catch (error) {
      console.error('API response error', error);
      setError( (error.message && error.message !== 'Failed to fetch') ? error.message : API_ERROR_MESSAGE);
    } finally {
      //setIsLoading(false);
    }
  };

  const skipVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Pause the video
    }
    handleNextStep();
  };

  const handleVideoPlay = () => {
    const video = videoRef.current;
    // Request full-screen mode for the video element
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { // Firefox
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { // IE/Edge
      video.msRequestFullscreen();
    }
  };

  const handleTap = () => {
    setTapCount(tapCount + 1);

    if (tapCount + 1 === 5) {
      skipVideo();
    } else {
      if (tapTimeout) {
        clearTimeout(tapTimeout);
      }

      // Reset tap count if no consecutive taps within 500ms
      const timeout = setTimeout(() => setTapCount(0), 500);
      setTapTimeout(timeout);
    }
  };

  useEffect(() => {
    document.body.classList.forEach((className) => {
      if (className.startsWith('fondo-')) {
        document.body.classList.remove(className);
      }
    });
    document.body.classList.add('fondo-' + fondo);
  }, [fondo]);

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'DDM Natura Maquillaje',
          text: 'Tu foto en DDM Natura Maquillaje',
          url: finalImage,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    }
  };

  const handlePhotoReset = () => {
    setStep(5);
    setImageIsLoaded(false);
  };

  const handleImageLoad = () => {
    setImageIsLoaded(true);
  };
 
  const goToStep = (step) => {
    setStep(step);
  };
  window.goToStep = goToStep;

  return (
    <div className="container mx-auto h-100 d-flex align-items-center flex-column justify-content-center text-center wizard-container p-0 m-0">
    
      <img src={"img/marco" + marco + "-top.png"} alt="" className="marco1 marco-top" />
      <img src={"img/marco" + marco + "-bottom.png"} alt="" className="marco1 marco-bottom" />
      <img src={"img/marco" + marco + "-left.png"} alt="" className="marco1 marco-left" />
      <img src={"img/marco" + marco + "-right.png"} alt="" className="marco1 marco-right" />

      {step === 0 && (
        <Step withHeader footer={<img src="img/footer1.png" alt="Natura"/>} className="step-no-padding">
          <BounceInLeft>
            <img src="img/home1.png" alt="Natura" className="w-100 mb-0" />
          </BounceInLeft>
          <BounceInRight>
            <img src="img/home2.png" alt="Natura" className="w-100 mb-2" />
          </BounceInRight>
          <Pulse>
            <img src="img/decor1.png" alt="Natura" className="w-decor-lg mb-0" />
          </Pulse>
          <FadeIn>
            <p className="mb-0 px-5 py-2">¡Pronto vas a vivir una experiencia única!</p>
          </FadeIn>
        </Step>
      )}

      {step === 1 && (
        <Step withHeader footer={<img src="img/footer1.png" alt="Natura"/>} className="step-no-padding">
          <BounceInLeft>
            <img src="img/home1.png" alt="Natura" className="w-100 mb-0" />
          </BounceInLeft>
          <BounceInRight>
            <img src="img/home2.png" alt="Natura" className="w-100 mb-2" />
          </BounceInRight>
          <Pulse>
            <img src="img/decor1.png" alt="Natura" className="w-decor-lg mb-0" />
          </Pulse>
          <FadeIn>
            <Button variant='primary' className="mb-5 with-decorator" onClick={handleNextStep}>Comenzar</Button>
          </FadeIn>
        </Step>
      )}
      
      {step === 2 && (
        <Step className="step-no-padding">
          <FadeIn>
            <div>
              <video 
                ref={videoRef}
                src="img/catrinav.mp4" 
                autoPlay 
                onPlay={handleVideoPlay}
                onEnded={handleNextStep} 
                style={{ width: '100%' }} 
                controls={false} 
                onClick={handleTap}
              />
            </div>
          </FadeIn>
        </Step>
      )}
      
      {step === 3 && (
        <Step withHeader footer={<img src="img/footer2.png" alt="Natura"/>}>
          <Pulse>
            <img src="img/decor2.png" alt="Natura" className="w-decor mb-0" />
          </Pulse>
          <FadeIn>
            <p>Cuéntame,<br/>¿cómo te llamas?</p>
            <input
              className='form-control mb-3'
              type="text"
              name="name"
              placeholder=""
              onChange={(event) => setNombre(event.target.value)}
            />
            {nombreError && <p style={{ color: 'red' }}>{nombreError}</p>}
            <Button variant='primary' onClick={handleNombreSubmit}>Enviar</Button>
          </FadeIn>
        </Step>
      )}
      
      {step === 4 && (
        <Step withHeader footer={<img src="img/footer2.png" alt="Natura"/>}>
          <Pulse>
            <img src="img/decor3.png" alt="Natura" className="w-decor mb-0" />
          </Pulse>
          <FadeIn>
            <p>Hermoso nombre <br/><strong>{nombre}</strong>.</p>
            <p>¿Me puedes compartir tu mail así quedamos en contacto?</p>
            <input
              className='form-control mb-3'
              type="email"
              name="email"
              placeholder=""
              onChange={(event) => setEmail(event.target.value)}
            />
            {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
            <Button variant='primary' onClick={handleEmailSubmit}>Enviar</Button>
          </FadeIn>
        </Step>
      )}
      
      {step === 5 && (
        <Step withHeader footer={<img src="img/footer3.png" alt="Natura"/>}>
          <Pulse>
            <img src="img/decor4.png" alt="Natura" className="w-decor mb-0" />
          </Pulse>
          <FadeIn>
            <p>Ahora, dime, <br/><strong>¿qué sitio de México te interesa más para pasar esta celebración?</strong></p>
            <div>
              <Button variant='primary' className='mx-2 mb-3' name="location" value="Ciudad" onClick={(e) => {setLugar(e.target.value); handleNextStep();}}>Ciudad</Button>
              <Button variant='primary' className='mx-2 mb-3' name="location" value="Pueblo" onClick={(e) => {setLugar(e.target.value);  handleNextStep();}}>Pueblo</Button>
              <br/>
              <Button variant='primary' className='mx-2 mb-3' name="location" value="Panteón" onClick={(e) => {setLugar(e.target.value);  handleNextStep();}}>Panteón</Button>
            </div>
          </FadeIn>
        </Step>
      )}
      
      {step === 6 && (
        <Step withHeader footer={<img src="img/footer3.png" alt="Natura"/>}>
          <Pulse>
            <img src="img/decor4.png" alt="Natura" className="w-decor mb-0" />
          </Pulse>
          <FadeIn>
            <p><strong>¿Cuál es tu momento <br/>preferido del día?</strong></p>
            <div>
              <Button variant='primary' className='mx-2 mb-3' name="timeOfDay" value="Día" onClick={(e) => {setMomento(e.target.value); handleNextStep();}}>Día</Button>
              <Button variant='primary' className='mx-2 mb-3' name="timeOfDay" value="Noche" onClick={(e) => {setMomento(e.target.value); handleNextStep();}}>Noche</Button>
            </div>
            <div>
              <Button variant='secondary' className='mx-2 mb-3' onClick={(e) => {handlePreviousStep();}}>Volver</Button>
            </div>
          </FadeIn>
        </Step>
      )}

      {step === 7 && (
        <Step withHeader>
          <Pulse>
            <img src="img/decor4.png" alt="Natura" className="w-decor mb-0" />
          </Pulse>
          <FadeIn>
            <p><strong>¿Qué maquillaje <br/>quieres usar?</strong></p>
            <div className="row px-5 mb-0">
              {
                maquillajes.map((maquillaje, index) => (
                  <div className="col-6" key={index}>
                    <Button className='btn-maquillaje' name="makeupStyle" value={maquillaje} onClick={(e) => {setMakeup(maquillaje); handleNextStep();}}>
                      <img src={`img/mu${maquillaje}.png`} alt="Maquillaje" />
                    </Button>
                  </div>
                ))
              }
            </div>
            <div>
              <Button variant='secondary' className='mx-2 mb-3' onClick={(e) => {handlePreviousStep();}}>Volver</Button>
            </div>
          </FadeIn>
        </Step>
      )}
      
      
      {step === 8 && (
        <Step withHeader>
          <FadeIn>
            <div className="decor5">
              <p><strong>¡Perfecto!</strong></p>
              <p className="px-4">Vamos a trasladarte a algún rincón mágico de México, luciendo un maquillaje que va a impactar en este Día de Muertos.</p>
            </div>
            <img src="img/footer4.png" alt="Natura" className="w-70 mt-2 mb-4" />
            <div>
              <Button variant='primary' className='mx-2 mb-4 with-decorator' onClick={() => { setParticipa(true); handleNextStep() }}>Siguiente</Button>
            </div>
          </FadeIn>
        </Step>
      )}

      {step === 9 && (
        <Step withHeader>
          <FadeIn>
            <div className="decor5">
              <p>&nbsp;</p>
              <p className="px-5">Tómate una foto selfie vertical, de frente, con buena iluminación.</p>
            </div>
            <img src="img/footer4.png" alt="Natura" className="w-70 mt-2 mb-4" />
            <div>
              <Button variant='primary' className='mx-2 mb-4 with-decorator' onClick={handleNextStep}>Comenzar</Button>
            </div>
          </FadeIn>
        </Step>
      )}
      
      {step === 10 && (
        <Step>
          <FadeIn>
            <PhotoProcessor uid={uid} makeup={makeup} background={background} handleImageProcessed={handleImageProcessed} cameraAvailable={cameraAvailable} handleReset={handlePhotoReset}>
            </PhotoProcessor>
          </FadeIn>
        </Step>
      )}

      {step === 11 && (
        <Step>
          <FadeIn>
            <img src={finalImage} alt="" style={{ width: '0', height: '0', opacity: 0 }} onLoad={() => setImageIsLoaded(true)}/>
            { finalImage && imageIsLoaded &&
              <Pulse>
                <div className="image-wrapper mb-4">
                  <img src={finalImage} alt="" />
                </div>
              </Pulse>
            }
            <div className="mt-3">
              <Button variant='primary' className='mx-2 mb-3' onClick={handleDownload}>Descargar</Button>
              { /*sharerDetected && <Button variant='primary' className='mx-2 mb-3' onClick={handleShare}>Compartir</Button> */}
              <Button variant='primary' className='mx-2 mb-3' onClick={handlePhotoReset}>Repetir</Button>
              <br/>
              <Button variant='primary' className='mx-2 mb-3' onClick={handleNextStep}>Continuar</Button>
            </div>
          </FadeIn>
        </Step>
      )}

      {step === 12 && (
        <Step withHeader footer={<img src="img/footer1.png" alt="Natura"/>} className="step-no-padding">
          <FadeIn>
            <div className="px-5">
              <div className="px-2 pt-4">
                <div className="decor5 px-2">
                  <p className="py-2"><strong>¡Impresionante!</strong></p>
                  <p className="px-0">Te enviaré la foto por mail asi puedes conservarla y compartirla con quien quieras.</p>
                </div>
                <img src="img/decor6.png" alt="Natura" className="w-80 mtm mb-2" />
                <div>
                  <Button variant='primary' className='mx-2' onClick={handleNextStep}>Siguiente</Button>
                </div>
              </div>
            </div>
          </FadeIn>
        </Step>
      )}

      {step === 13 && (
        <Step withHeader>
          <FadeIn>
            <p className="mb-0"><strong>Gracias {nombre}.</strong></p>
            <p className="mb-2">por vivir esta experiencia</p>
            <img src="img/naturafaces2.png" alt="Natura" className="w-90 mt-0 mb-1" />
            <Button variant='primary' className='mx-2 mb-2 btn-text-small' onClick={() => handleRedirect(URL_FINAL)}>Descubre los productos Natura<br/>para replicar tu look de maquillaje.</Button>
            <p className="text-sm mb-5">¡Viva la vida, viva la muerte, vivan los colores!</p>
          </FadeIn>
        </Step>
      )}
      
      {/*step === 5 && (
        <FadeIn>
          <p>¡Excelente, avancemos!<br/>Quisiera conocer cuál es tu rutina de maquillaje para esta fecha tan especial y cómo vas a celebrar este año.</p>
          <textarea
            className='form-control mb-3'
            name="routine"
            placeholder=""
            onChange={(event) => { handleTextareaChange(event); setRutina(event.target.value); }}
          />
          {rutinaError && <p style={{ color: 'red' }}>{rutinaError}</p>}
          <Button variant='primary' onClick={handleRutinaSubmit}>Enviar</Button>
        </FadeIn>
      )*/}

              
      {/*step === 5 && (
        <Step withHeader footer={<img src="img/footer2.png" alt="Natura"/>}>
          <FadeIn>
            <p>¿Te animas a emprender un viaje por México luciendo un maquillaje de Día de Muertos que seguro va a impactar?</p>
            <div>
              <Button variant='primary' className='mx-2 mb-3' onClick={() => { setParticipa(true); handleNextStep(); }}>¡Sí, claro!</Button>
              <Button variant='primary' className='mx-2 mb-3' onClick={() => { setParticipa(false); handleRedirect(URL_NO_PARTICIPA); }}>No, gracias</Button>
            </div>
          </FadeIn>
        </Step>
      )*/}

    </div>
  );

}

export default App;
